// islands/CardManager.tsx
import { useEffect, useState } from "preact/hooks";
import CardList from "./CardList.tsx";
import DatabaseSelector from "./DatabaseSelector.tsx";
import CollectionEditor from "./CollectionEditor.tsx";
import HexGridEditor from "./HexGridEditor.tsx";

interface Card {
  Name: string;
  Type?: string;
  Attack?: number;
  Range?: number;
  Life?: number;
  MovementSpeed?: number;
  Initiative: number;
  Cost: number;
  Effects: Array<{
    Trigger: string;
    Type: string;
    Description: string;
  }>;
}

interface CardManagerProps {
  initCards: Card[];
  initialDatabase: string;
}

export default function CardManager({
  initCards,
  initialDatabase,
}: CardManagerProps) {
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [existingLevels, setExistingLevels] = useState<number[]>([]);
  const [cards, setCards] = useState(initCards);
  
  const [currentDatabase, setCurrentDatabase] = useState(initialDatabase);
  const [error, setError] = useState<string | null>(null);

  const [isCollectionExpanded, setIsCollectionExpanded] = useState(false);
  const [isCardsExpanded, setIsCardsExpanded] = useState(false);
  const [isLevelEditorExpanded, setIsLevelEditorExpanded] = useState(false);

  const ExpanderHeader = (
    { title, isExpanded, onClick }: {
      title: string;
      isExpanded: boolean;
      onClick: () => void;
    },
  ) => (
    <div
      class="flex items-center justify-between p-4 bg-gray-100 rounded-t cursor-pointer hover:bg-gray-200"
      onClick={onClick}
    >
      <h2 class="text-xl font-semibold">{title}</h2>
      <span class="text-xl font-bold">{isExpanded ? "▼" : "▶"}</span>
    </div>
  );

  const loadLevels = async () => {
    try {
      const response = await fetch(
        `/api/levelEditor?database=${
          encodeURIComponent(currentDatabase)
        }&levelNumber=1`,
      );
      if (!response.ok) throw new Error("Failed to load levels");
      const data = await response.json();
      setExistingLevels(data.existingLevels);
    } catch (err) {
      setError("Failed to load levels");
    }
  };

  // Load levels when database changes
  useEffect(() => {
    loadLevels();
  }, [currentDatabase]);

  const handleDatabaseSelect = async (database: string) => {
    try {
      const response = await fetch(`/?database=${database}`);
      if (!response.ok) throw new Error("Failed to fetch data");
      const data = await response.json();

      setCards([...data.units,...data.spells]);

      setCurrentDatabase(database);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCardUpdate = async (fileType: string, cardData: string) => {
    try {
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("fileType", fileType);
      formData.append("cardData", cardData);
      formData.append("database", currentDatabase);

      const response = await fetch("/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to update card");
      const data = await response.json();

      setCards([...data.units,...data.spells]);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div class="p-4 mx-auto max-w-screen-lg">
      <h1 class="text-3xl font-bold mb-8">Mythological Card Manager</h1>

      <DatabaseSelector
        currentPath={currentDatabase}
        onSelect={handleDatabaseSelect}
      />

      {error && (
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div class="space-y-4">
        {/* Collection Editor Section */}
        <div class="border rounded shadow">
          <ExpanderHeader
            title="Collection Editor"
            isExpanded={isCollectionExpanded}
            onClick={() => setIsCollectionExpanded(!isCollectionExpanded)}
          />
          {isCollectionExpanded && (
            <div class="p-4 border-t">
              <CollectionEditor database={currentDatabase} />
            </div>
          )}
        </div>

        {/* Cards Section */}
        <div class="border rounded shadow">
          <ExpanderHeader
            title="Cards"
            isExpanded={isCardsExpanded}
            onClick={() => setIsCardsExpanded(!isCardsExpanded)}
          />
          {isCardsExpanded && (
            <div class="p-4 border-t">
              <div class="gap-8">
                <CardList
                  cards={cards}
                  type="units"
                  database={currentDatabase}
                  onCardUpdate={handleCardUpdate}
                />
              </div>
            </div>
          )}
        </div>
        <div class="border rounded shadow">
          <ExpanderHeader
            title="Level Editor"
            isExpanded={isLevelEditorExpanded}
            onClick={() => setIsLevelEditorExpanded(!isLevelEditorExpanded)}
          />
          {isLevelEditorExpanded && (
            <div class="p-4 border-t">
              <div class="mb-4 flex gap-4">
                <div class="flex-1">
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    Select or Create Level
                  </label>
                  <div class="flex gap-2">
                    {existingLevels.length > 0 && (
                      <select
                        class="p-2 border rounded"
                        value={selectedLevel}
                        onChange={(e) =>
                          setSelectedLevel(parseInt(e.currentTarget.value))}
                      >
                        <option value="">Select Level...</option>
                        {existingLevels.map((level) => (
                          <option key={level} value={level}>
                            Level {level}
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      type="number"
                      min="1"
                      class="w-32 p-2 border rounded"
                      value={selectedLevel}
                      onChange={(e) =>
                        setSelectedLevel(parseInt(e.currentTarget.value) || 1)}
                      placeholder="New Level #"
                    />
                    <button
                      class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                      onClick={() =>
                        setSelectedLevel(Math.max(...existingLevels, 0) + 1)}
                    >
                      New Level
                    </button>
                  </div>
                </div>
              </div>

              <HexGridEditor
                database={currentDatabase}
                levelNumber={selectedLevel}
                onLevelsChange={loadLevels} // Add this prop
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
